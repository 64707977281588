/* Thêm đoạn này vào cashierSidebar.css */
@media (max-width: 1024px) {
    .card {
        min-width: 56px;
        /* Thu hẹp chiều rộng của sidebar */
        padding: 10px;
        /* Giảm padding */
    }

    .card button {
        padding: 8px;
        width: 25% !important;
        /* Điều chỉnh khoảng cách cho phù hợp */
    }

    .card button span {
        display: none;
        /* Ẩn văn bản */
    }

    .card .size-6 {
        width: 24px;
        height: 24px;
        /* Điều chỉnh kích thước của icon */
    }
}