/* General Styles */



/* Main Dashboard Container */
.MainDash {
    margin-left: 15%;
    padding: 1.5rem;
}

/* Cards */
.parentContainer {
    display: flex;
    gap: 10px;
}

/* Compact Card */
.CompactCard {
    width: 250px;
    background: linear-gradient(rgb(187, 103, 255) 0%, rgb(196, 132, 243) 100%);
    box-shadow: rgb(224, 198, 245) 0px 10px 20px;
    border-radius: 0.7rem;
    color: white;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    cursor: pointer;
}

/* Radial Progress Bar */
.radialBar {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    gap: 1rem;
}

.CircularProgressbar {
    width: 4rem;
    margin: 0 auto;
}

.CircularProgressbar-path {
    stroke: white;
    stroke-width: 12px;
}

.ck-editor__editable {
    min-height: 100px;
}

.CircularProgressbar-trail {
    stroke: rgba(255, 255, 255, 0.2);
    stroke-width: 12px;
}

.CircularProgressbar-text {
    fill: white;
    font-size: 12px;
    font-weight: bold;
    text-anchor: middle;
}

/* Card Detail */
.detail {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-between;
}

.detail > span:nth-child(1) {
    font-size: 22px;
    font-weight: bold;
}

.detail > span:nth-child(2) {
    font-size: 12px;
}

/* Expanded Card */
.ExpandedCard {
    display: none;
    position: absolute;
    width: 70%;
    height: 80vh;
    background: linear-gradient(rgb(187, 103, 255) 0%, rgb(196, 132, 243) 100%);
    box-shadow: rgb(224, 198, 245) 0px 10px 20px;
    border-radius: 1rem;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    padding: 1rem;
    z-index: 999;
}

.ExpandedCard > span:nth-of-type(1) {
    color: white;
    font-size: 26px;
    font-weight: bold;
    text-shadow: 0px 0px 15px white;
}

.chartContainer {
    width: 100%;
    height: 90%; /* Biểu đồ sẽ chiếm toàn bộ chiều rộng và chiều cao của container */
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 1rem;
}

#chart {
    width: 100%;
    height: 100%; /* Biểu đồ sẽ mở rộng ra toàn bộ vùng chứa */
}

.close {
    align-self: flex-end;
    cursor: pointer;
    font-size: 2rem;
    color: white;
}

/* Media Queries */
@media screen and (max-width: 1200px) {
    .ExpandedCard {
        top: 2rem;
        height: 45vh;
        left: 6rem;
    }
}

@media screen and (max-width: 768px) {
    .ExpandedCard {
        top: 8rem;
        height: 50%;
        left: 25px;
        width: 80%;
    }
}

.chartContainer {
    width: 100%;
    height: 100%;
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 1rem;
}

#chart {
    width: 100%;
    height: 100%;
}

/* Cards */
.cards {
    display: flex;
    gap: 20px;

}

.detail {
    flex: 1 1;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-between;
}



.card.sales {
    background: linear-gradient(rgb(187, 103, 255) 0%, rgb(196, 132, 243) 100%);
    box-shadow: rgb(224, 198, 245) 0px 10px 20px 0px;
}

.card.revenue {
    background: linear-gradient(rgb(255, 145, 157) 0%, rgb(252, 146, 157) 100%);
    box-shadow: rgb(253, 192, 199) 0px 10px 20px 0px;
}

.card.expenses {
    background: linear-gradient(rgb(248, 212, 154) -146.42%, rgb(255, 202, 113) -46.42%);
    box-shadow: rgb(249, 213, 155) 0px 10px 20px 0px;
}

.progress-circle {
    flex: 1 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    gap: 1rem;
}

.progress-circle span {
    font-size: 14px;
    color: #030303;
}

.card h3 {
    margin: 50px 0 10px;
    font-size: 18px;
    font-weight: bold;
}

.card h1 {
    font-size: 28px;
    font-weight: bold;
}

.card span {
    font-size: 12px;
    opacity: 0.8;
}

.icon {
    position: absolute;
    top: 20px;
    right: 20px;
    font-size: 24px;
    opacity: 0.7;
}

/* Recent Orders */
.recent-orders {
    margin-top: 2rem;
    background-color: white;
    padding: 1.5rem;
    border-radius: 15px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.recent-orders h2 {
    margin-bottom: 1rem;
}

.orders-table {
    width: 100%;
    border-collapse: collapse;
}

.orders-table th, .orders-table td {
    padding: 0.75rem 1rem;
    text-align: left;
}

.orders-table th {
    background-color: #f4f4f9;
    color: #333;  
}

.orders-table tr {
    border-bottom: 1px solid #f4f4f9;
}

.status {
    padding: 0.4rem 0.8rem;
    border-radius: 30px;
    font-size: 0.9rem;
}

.status.approved {
    background-color: #d4edda;
    color: #155724;
}

.status.pending {
    background-color: #f8d7da;
    color: #721c24;
}

.status.delivered {
    background-color: #d1ecf1;
    color: #0c5460;
}

.details-link {
    color: #007bff;
    cursor: pointer;
}

/* Updates Section */
.updates {
    margin-top: 2rem;
    background-color: white;
    padding: 1.5rem;
    border-radius: 15px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.update-item {
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-bottom: 1rem;
}

.update-item img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
}

.update-info {
    font-size: 0.9rem;
    color: #666;
}

/* Chart Section */
.customer-review {
    margin-top: 2rem;
    background-color: white;
    padding: 1.5rem;
    border-radius: 15px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}





/* Activity and Payment */

.chart-row {
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
}

.chart-card {
    background:none; /* Dark background */
    padding: 15px;
    border-radius: 10px;
    width: 48%;
    height: 500px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
}

.chart-card h3 {
    color: rgb(0, 0, 0);
    margin-bottom: 20px;
}

#activityChart, #paymentChart {
    height: 300px; /* Set height for charts */
}


/* General card styling */
.card.financial-overview {
    /* background-color: #1e1e1e; */
    background: none;
    color: rgb(0, 0, 0);
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
    width: 51vh;
    height: 84.5vh;
}

.progress-circle {
    text-align: center;
    margin-bottom: 20px;
}

.circle {
    position: relative;
}

.circular-chart {
    width: 100px;
    height: 100px;
}

.circle-bg {
    fill: none;
    stroke: #eaeaea;
    stroke-width: 3.8;
}

.circle {
    fill: none;
    stroke-width: 2.8;
    stroke: #ff4e50;
    stroke-linecap: round;
    animation: progress 1s ease-out forwards;
}

.percentage {
    fill: rgb(0, 0, 0);
    font-size: 10px;
    text-anchor: middle;
}

.label, .balance-text {
    display: block;
    color: #aaa;
    margin-top: 10px;
}

.available-balance h2 {
    font-size: 32px;
    margin: 0;
}

.available-balance p {
    color: #bbb;
}

.categories {
    margin-top: 20px;
}

.category-item {
    display: flex;
    justify-content: space-between;
    margin: 10px 0;
    padding-bottom: 10px;
    border-bottom: 1px solid #333;
}

.category-item .icon {
    margin-right: 10px;
}

.amount {
    color: #e94e77;
}



@keyframes progress {
    0% {
        stroke-dasharray: 0, 100;
    }
    100% {
        stroke-dasharray: 68, 100;
    }
}


/* General chart styling */
.chart-container {
    width: 100%;
    height: 300px; /* Set the same height for all charts */
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 1rem;
}

#chart, #revenueChart, #expensesChart, #activityChart, #paymentChart {
    width: 100%;
    height: 100%; /* Ensure charts take full height */
}




@media (max-width: 1000px)  {
    .MainDash{
    margin-left: 0%;
    padding: 1.5rem;
    }
    .admin-layout{
        width: 90%;
    }

    .topbar{
        width: 1000px !important;
        margin-left: 0px;
    }

    .main-content{
        margin: 0;
        padding: 0;
    }

    .product-management{
        padding: 50px 100px;
    }














    .promotion-management{
        padding: 50px 100px;
    }



    .promotion-management {
        padding: 10px; /* Giảm khoảng cách padding để phù hợp với màn hình nhỏ */
      }
    
      .search-filter {
        flex-direction: column; /* Chuyển đổi thành cột để hiển thị gọn gàng */
        align-items: flex-start;
        gap: 10px; /* Tạo khoảng cách giữa các phần tử */
      }
    
      .search-wrapper {
        width: 100%; /* Chiếm toàn bộ chiều rộng */
      }
    
      .search-input {
        width: 100%; /* Đầu vào tìm kiếm chiếm toàn bộ khung */
      }
    
      .btn-export-excel {
        flex-wrap: wrap; /* Đưa các nút xuống dòng nếu không đủ không gian */
        justify-content: flex-start;
        gap: 10px; /* Tạo khoảng cách giữa các nút */
      }
    
      .table-container {
        width: 100vw; /* Chiếm toàn bộ chiều rộng màn hình */
        overflow-x: auto; /* Thêm thanh cuộn ngang nếu bảng quá rộng */
        margin-left: -10px; /* Loại bỏ khoảng trống từ container cha */
        margin-right: -10px;
      }
    
      table {
        width: 100%; /* Đảm bảo bảng chiếm toàn bộ khung container */
      }
    
      thead th {
        font-size: 12px; /* Thu nhỏ kích thước chữ ở tiêu đề bảng */
        white-space: nowrap; /* Đảm bảo tiêu đề không bị xuống dòng */
      }
    
      tbody td {
        font-size: 12px; /* Thu nhỏ kích thước chữ trong bảng */
        white-space: nowrap; /* Giữ văn bản trên một dòng */
      }
    
      .icon-button-edit,
      .icon-button-remove {
        padding: 5px; /* Giảm kích thước nút để phù hợp với không gian nhỏ */
        font-size: 10px;
      }
    
      .add-employee-btn {
        width: 100%; /* Đặt nút Add Promotion chiếm toàn bộ chiều rộng */
        text-align: center;
      }











    .chart-row {
        display: flex;
        flex-wrap: wrap; /* Cho phép các chart tự xuống dòng */
        justify-content: center; /* Canh giữa nội dung */
        gap: 15px; /* Khoảng cách giữa các chart */
        margin: 0 auto; /* Căn giữa cả hàng */
        padding: 10px; /* Khoảng cách xung quanh */
      }
    
      .chart-card {
        height: 100%;
        width: 100%; /* Đảm bảo chart chiếm toàn bộ chiều rộng của màn hình nhỏ */
        max-width: 500px; /* Giới hạn chiều rộng tối đa để không quá lớn */
        margin: 0 auto; /* Căn giữa từng chart */
        padding: 15px; /* Khoảng cách trong mỗi chart */
        
        border-radius: 8px; /* Bo góc */
        
      }
    
      .chart-card h3 {
        font-size: 1.1rem; /* Giảm kích thước tiêu đề cho màn nhỏ */
        text-align: center;
        margin-bottom: 10px;
        color: #ffffff; /* Giữ màu chữ trắng */
      }
    
      .chart-card p {
        text-align: center; /* Căn giữa thông báo 'Loading...' hoặc lỗi */
        color: #f0f2f5;
      }


  





      .main-content {
        padding: 10px; /* Giảm khoảng cách thụt vào */
      }
    
      .employee-management h2 {
        font-size: 1.5rem; /* Giảm kích thước tiêu đề */
        text-align: center; /* Căn giữa tiêu đề */
      }
    
      .search-filter {
        flex-direction: column; /* Chuyển thành dạng cột */
        align-items: flex-start; /* Căn trái các phần tử */
        gap: 10px; /* Thêm khoảng cách giữa các phần tử */
      }
    
      .search-wrapper {
        width: 100%; /* Chiếm toàn bộ chiều rộng */
        position: relative;
      }
    
      .search-input {
        width: 100%; /* Đảm bảo input vừa khung */
        padding: 8px 35px 8px 10px; /* Điều chỉnh khoảng cách bên trong */
      }
    
      .search-icon {
        position: absolute;
        top: 50%;
        right: 10px;
        transform: translateY(-50%);
        font-size: 1.2rem;
        color: #aaa;
      }
    
    
      table {
        font-size: 0.9rem; /* Giảm kích thước chữ */
        width: 100%; /* Chiếm toàn bộ chiều rộng */
      }
    
      table thead th {
        white-space: nowrap; /* Ngăn tiêu đề bảng bị ngắt dòng */
      }
    
      table tbody td {
        white-space: nowrap; /* Ngăn dữ liệu trong bảng bị ngắt dòng */
        text-overflow: ellipsis; /* Hiển thị dấu "..." nếu dữ liệu quá dài */
        overflow: hidden;
      }
    
      .icon-button-edit,
      .icon-button-remove {
        padding: 5px; /* Giảm kích thước các nút chỉnh sửa/xóa */
        font-size: 0.8rem;
      }
    
      .add-employee-btn {
        width: 100%; /* Đặt nút thêm nhân viên chiếm toàn bộ chiều rộng */
        text-align: center;
      }

      .table-container {
        width: 92vw; /* Chiếm toàn bộ chiều rộng màn hình */
        margin-left: 10px;
        margin-right: -10px;
        overflow-x: auto; /* Đảm bảo bảng có thể cuộn ngang nếu nội dung quá lớn */
      }
    
      table {
        width: 100%; /* Đảm bảo bảng chiếm toàn bộ chiều rộng khung */
      }
    
      .main-content {
        padding: 10px 0; /* Loại bỏ khoảng cách ngang để khớp với màn hình */
      }
      








   .admin-layout .menu{
        display: none ;
    }

    .sidebar_admin{
        display: none;
    }

   

    .topbar{
        padding: 5% 20% !important;
        justify-content: space-between;
    }

    .profile   .dropdown-menu{
        width: 20px !important;
    }

   .profile    .dropdown-item {
        font-size: 10px;
    
    }

   .parentContainer{
    display: flex;
    justify-content: space-between;
    margin-left: -auto123px;
   }

   .CompactCard{
    width: 187px; /* Smaller width */
    height: 180px; /* Smaller height */
    margin: 10px auto; /* Center the cards with a small margin */
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
   }

   .workshifts{
        padding: 50px 100px;
   }

}

iframe#webpack-dev-server-client-overlay{display:none!important}


