* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

:root {
    --colorMain: #fffefb;
    --colorPrimary: #bd4242;
    --colorBoder: #ffffff1d;
    --colorFont: #1e1e2f;
    --color1: #ffffff;
    --container_lg: 76%;
    --container_md: 90%;
    --container_sm: 94%;
    --transition: all 400ms ease;
    --logo: url('../img/warenbuffet.png');
}

/* Light Theme */
body.light {
    --bg-color: hsl(228, 100%, 99%);
    --nav-bg-color: #f8f9fa;
    --nav-text-color: #000000;
    --first-color: #bd4242;
    --title-color: hsl(228, 18%, 16%);
    --text-color: hsl(229, 6%, 36%);
    --body-color: hsl(228, 100%, 99%);
    --body-color-table: hsl(228, 100%, 99%);
    --shadow-color: hsla(228, 80%, 4%, .1);
    --body-color-table-active: #f6e6c6;
    /* Thêm các biến khác nếu cần */
  }
  
  /* Dark Theme */
  body.dark {
    --bg-color: hsl(228, 24%, 16%);
    --nav-bg-color: #343a40;
    --nav-text-color: #ffffff;
    --first-color: #bd4242;
    --title-color: hsl(228, 18%, 96%);
    --text-color: hsl(228, 12%, 61%);
    --body-color: hsl(228, 24%, 16%);
    --body-color-table: hsl(228, 31%, 19%);
    --body-color-table-active: hsl(230, 26%, 23%);
    --shadow-color: hsla(228, 80%, 4%, .3);
    --logo: url('../img/warenbuffetDark.png');
    /* Thêm các biến khác nếu cần */
  }

header {
    position: fixed;
    width: 100%;
    z-index: 2;
}

.form-control::placeholder{
    color: var(--text-color);
}

body{
    background-color: var(--body-color) !important;
    color:var(--text-color) !important ;
}

h1,
h2,
h3,
h4,
h5 {
    line-height: 1.2;
    color:var(--text-color) !important ;
}

h1 {
    font-size: 2.4rem;
}

h2 {
    font-size: 2rem;
}

h3 {
    font-size: 1.6rem;
}

h4 {
    font-size: 1.3rem;
}

a {
    text-decoration: none;
}

span {
    color: var(--colorPrimary);
}

/* General Styles */
.left-section {
    padding: 20px;
}

.left-section img {
    border-radius: 10px;
}

.accordion-header button{
    background-color: var(--body-color);
    color: var(--text-color);
}

.accordion-header .accordion-body{
    color: var(--text-color);
}

.right-section {
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.right-section .image-card {
    position: relative;
    border-radius: 10px;
    overflow: hidden;
}

.right-section img {
    width: 100%;
    height: 200px;
    border-radius: 10px;
    transition: transform 0.3s ease-in-out;
}

.right-section .btn {
    position: absolute;
    right: 12px;
    bottom: 0px;
    font-size: 16px;
    font-weight: bold;
    color: white;
    background-color: #d73434;
    padding: 10px 20px;
    text-decoration: none;
    transition: background-color 0.3s ease;
    border: none;
}



.text-overlay {
    position: absolute;
    bottom: 30px;
    left: 30px;
    color: white;
    font-size: 48px;
    font-weight: bold;
    z-index: 2;
}








/* Menu Bar Styles */
.menu-bar {
    position: absolute;
    top: 20px;
    left: 20px;
    padding: 10px 30px;
    width: auto;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 15px;
    z-index: 10;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    background-color: var(--body-color);
    
}

.menu-bar i {
    color: var(--text-color);
    font-size: 30px;
    margin-right: 10px;
    font-weight: bold;
}

/* Hover Effect: Change the menu icon image */
.menu-bar .menu-icon:hover {
    content: url('./../img/menu (1).png');
    /* Replace with the path to the new image */
    transform: scale(1.1);
    /* Slight scale effect */
}

.menu-bar .logo {
    height: 50px;
    margin-right: 15px;
}

.menu-bar .brand {
    color: var(--colorFont);
    font-weight: bold;
    font-size: 24px;
    text-decoration: none;
    display: flex;
    align-items: center;
}

.brand .logo {
    width: 100px; /* Đặt chiều rộng logo */
    height: 50px; /* Đặt chiều cao logo */
    background-image: var(--logo); /* Đường dẫn đến hình ảnh logo */
    background-size: contain; /* Điều chỉnh kích thước hình ảnh */
    background-repeat: no-repeat; /* Không lặp lại hình ảnh */
    background-position: center; /* Căn giữa hình ảnh */
    /* Tùy chọn thêm: */
    display: block; /* Đảm bảo div có kích thước */
}

.menu-bar .nav-link {
    color: var(--colorFont);
    margin-right: 20px;
    font-size: 16px;
    text-decoration: none;
    font-weight: bold;
    color: var(--nav-text-color);
}

.menu-bar .nav-link:hover {
    text-decoration: underline;
}

.menu-bar .btn-book {
    color: var(--colorFont);
    border: 2px solid var(--text-color);
    padding: 5px 15px;
    border-radius: 20px;
    text-decoration: none;
    font-weight: bold;
    background-color: var(--body-color-table); /* Hoặc sử dụng biến khác nếu cần */
    color: var(--text-color);
}

.menu-bar .btn-book:hover {
    background-color: var(--body-color);
    color: white;
}

.btn-user {
    margin-left: 20px;
}

.cart-icon {
    margin: 0 10px 0 30px;
    font-size: 25px !important;
}

.user p {
    color: var(--colorFont);
    font-weight: bold;
}

.left-section::-webkit-scrollbar {
    display: none;
    /* Ẩn thanh cuộn */
}

#decrement {
    width: 30px;
    height: 30px;
    border-radius: 50%;
}

#increment {
    width: 30px;
    height: 30px;
    border-radius: 50%;
}

.hot-deal input[type="number"] {
    text-align: center;
    font-size: 16px;
    border: none;
    background-color: var(--body-color);
    color: #202030;
    width: 35px;
}

.profile-img {
    margin-left: 45%;
}

.what-can-we-do button,
.what-can-we-do .accordion-body,
.what-can-we-do p {
    font-size: 0.9rem;
}



.what-can-we-do .accordion {
    max-height: 900px;
}

.text-span-custom {
    color: var(--text-color);
}



.accordion-item .accordion-item-design:not(.collapsed) {
    background-color: var(--colorPrimary);
    color: #fff;
    outline: none !important;
    border: none !important;
}



.hot-deal button {
    color: #fff;
    background-color: var(--colorPrimary);
    border: 1px solid var(--bs-border-color);
    font-size: 1rem;
    cursor: pointer;
}

.card-custom {
    width: 280px;
    /* background-color: var(--); */
    cursor: pointer;
}

@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&display=swap');

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Montserrat', sans-serif;
}

.ps28277 .container {
    background-color: #fff;
    border-radius: 30px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.35);
    position: relative;
    overflow: hidden;
    width: 800px;
    max-width: 100%;
    min-height: 650px;
    margin-top: 2%;
}

.ps28277 .container p {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.3px;
    margin: 20px 0;
}

.ps28277 .container span {
    font-size: 12px;
}

.ps28277 .container a {
    color: #333;
    font-size: 13px;
    text-decoration: none;
    /* margin: 15px 0 10px; */
}

.ps28277 .container button {
    background-color: #512da8;
    color: #fff;
    font-size: 12px;
    padding: 10px 45px;
    border: 1px solid transparent;
    border-radius: 8px;
    font-weight: 600;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    margin-top: 10px;
    cursor: pointer;
}

.ps28277 .container button.hidden {
    background-color: transparent;
    border-color: #fff;

}



.ps28277 .container form {
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0 40px;
    height: 100%;
}

.ps28277 .container input {
    background-color: #eee;
    border: none;
    margin: 8px 0;
    padding: 10px 15px;
    font-size: 13px;
    border-radius: 8px;
    width: 100%;
    outline: none;
}

/* Checkbox styles */
.ps28277 .terms {
    margin-top: 10px;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: left;
    margin-bottom: 5px;

}

.ps28277 #signin-agree {
    width: 10px;
}

.ps28277 #signup-agree {
    width: 10px;
}

.ps28277 .terms span {
    margin-right: 4px;
}

.ps28277 .terms input[type="checkbox"] {
    margin-right: 5px;
}

.ps28277 .forget-your-password {
    margin-bottom: 5px;
}

.ps28277 .form-container {
    position: absolute;
    top: 0;
    height: 100%;
    transition: all 0.6s ease-in-out;
}

.ps28277 .sign-in {
    left: 0;
    width: 50%;
    z-index: 2;
}

.ps28277 .container.active .sign-in {
    transform: translateX(100%);
}

.ps28277 .sign-up {
    left: 0;
    width: 50%;
    opacity: 0;
    z-index: 1;
}

.ps28277 .container.active .sign-up {
    transform: translateX(100%);
    opacity: 1;
    z-index: 5;
    animation: move 0.6s;
}

@keyframes move {

    0%,
    49.99% {
        opacity: 0;
        z-index: 1;
    }

    50%,
    100% {
        opacity: 1;
        z-index: 5;
    }
}

.ps28277 .social-icons {
    margin: 20px 0;
}

.ps28277 .social-icons a {
    border: 1px solid #ccc;
    border-radius: 20%;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin: 0 3px;
    width: 40px;
    height: 40px;
}

.ps28277 .toggle-container {
    position: absolute;
    top: 0;
    left: 50%;
    width: 50%;
    height: 100%;
    overflow: hidden;
    transition: all 0.6s ease-in-out;
    border-radius: 150px 0 0 100px;
    z-index: 1000;
}

.ps28277 .container.active .toggle-container {
    transform: translateX(-100%);
    border-radius: 0 150px 100px 0;
}

.ps28277 .toggle {
    background: url('../img/kichi-home-all.png') no-repeat center center/cover;
    /* background-color: #512da8; */
    height: 100%;
    /* background: linear-gradient(to right, #5c6bc0, #512da8); */
    color: #fff;
    position: relative;
    left: -100%;
    height: 100%;
    width: 200%;
    transform: translateX(0);
    transition: all 0.6s ease-in-out;
}

.ps28277 .container.active .toggle {
    transform: translateX(50%);
}

.ps28277 .toggle-panel {
    position: absolute;
    width: 50%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0 30px;
    text-align: center;
    top: 0;
    transform: translateX(0);
    transition: all 0.6s ease-in-out;
}

.ps28277 .toggle-left {
    transform: translateX(-200%);
}

.ps28277 .container.active .toggle-left {
    transform: translateX(0);
}

.ps28277 .toggle-right {
    right: 0;
    transform: translateX(0);
}

.ps28277 .container.active .toggle-right {
    transform: translateX(200%);
}



@media (max-width: 768px) {


    .voucher-image,
    .voucher-date {
        display: none;
    }

    .card-custom {
        width: 188px;
        cursor: pointer;
    }

    /* Menu Bar Styles */
    .menu-bar {
        width: 100%;
        position: relative;
        top: 0;
        left: 0;
        border-radius: 0;
        padding: 5px 5px;
    }

    .menu-bar i {
        float: right;
    }

    .nav-link {
        display: none !important;
    }

    .about img {
        width: 100%;
    }

    .about h4 {
        font-size: 1rem;
        text-align: center;
    }

    .nav-link-menu {
        display: block !important;
    }

    .btn-book {
        /* height: 50px; */
        font-size: 15px;
    }

    .about p {
        font-size: 0.9rem;
        padding-top: 0 !important;
        margin: 0;
    }

    .hide {
        display: none;
    }

    .help {
        padding-bottom: 24px;
    }

    .what-can-we-do h4 {
        font-size: 1rem;
    }



    .user p {
        display: none;
    }

    /* .hot-deal .row {
        display: inline;
    } */

    .hot-deal .col-6 {
        flex: 1 1 auto;
        max-width: 100%;
    }

    /*Phần HOT DEAL trang chủ Customer */
    .hot-deal .card {
        background-color: var(--bs-light-bg-subtle);
        border-radius: 6px;
        /* Bo góc nhẹ */
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        /* Tạo bóng nhẹ */
        padding: 0.75rem;
        /* Giảm padding trong card */
        max-width: 100%;
        /* Đảm bảo card không vượt quá 100% chiều rộng */
        height: 220px !important;
    }



    .hot-deal img {
        width: 100%;
        /* Đảm bảo hình ảnh chiếm toàn bộ chiều ngang card */
        height: 150px;
        /* Đặt chiều cao cố định cho hình ảnh */
        object-fit: cover;
        /* Giữ tỷ lệ hình ảnh */
        border-radius: 5px;
        /* Bo góc nhẹ cho hình ảnh */
    }

    .hot-deal .card h5 {
        font-size: 0.9rem !important;
        /* Giảm kích thước chữ */
        margin-top: 0.5rem;
    }

    .hot-deal .card h6 {
        font-size: 0.85rem;
        /* Giảm kích thước chữ của giá */
        margin-top: 0.3rem;
    }

    .hot-deal .card__price {
        margin-top: 0;
        font-weight: bold;
        color: var(--colorPrimary);
    }



    .hot-deal .col-6 {
        flex: 1 1 auto;
        max-width: 100%;
    }

    .hot-deal .card {
        background-color: var(--bs-light);
        border-radius: 6px;
        /* Bo góc nhẹ */
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        /* Tạo bóng nhẹ */
        padding: 0.75rem;
        /* Giảm padding trong card */
        max-width: 100%;
        /* Đảm bảo card không vượt quá 100% chiều rộng */
        height: 29vh;
    }

    .hot-deal img {
        /* Đảm bảo hình ảnh chiếm toàn bộ chiều ngang card */
        height: 150px;
        /* Đặt chiều cao cố định cho hình ảnh */
        object-fit: cover;
        /* Giữ tỷ lệ hình ảnh */
        border-radius: 5px;
        /* Bo góc nhẹ cho hình ảnh */
    }

    .hot-deal .card h5 {
        font-size: 0.9rem;
        /* Giảm kích thước chữ */
        margin-top: 0.5rem;
    }

    .hot-deal .card h6 {
        font-size: 0.85rem;
        /* Giảm kích thước chữ của giá */
        margin-top: 0.3rem;
    }

    .hot-deal .card__price {
        margin-top: 0 !important;
        font-weight: bold;
        color: var(--colorPrimary);
    }








    .checkout.spad {
        display: flex;
        justify-content: center;
        /* Căn giữa theo chiều ngang */
        align-items: center;
        /* Căn giữa theo chiều dọc */
        min-height: 100vh;
        /* Chiều cao tối thiểu 100% màn hình */
        padding: 1rem;
        /* Khoảng cách xung quanh */
        box-sizing: border-box;
    }

    .checkout .container {
        width: 100%;
        /* Chiếm 100% không gian container */
        max-width: 500px;
        /* Giới hạn chiều rộng tối đa */
        margin: 0 auto;
        /* Đặt container ở giữa */
    }

    .checkout .row {
        display: flex;
        flex-direction: column;
        /* Hiển thị theo cột trên màn hình nhỏ */
        align-items: center;
        /* Căn giữa nội dung bên trong theo chiều ngang */
        justify-content: center;
        /* Căn giữa nội dung theo chiều dọc */
        gap: 1rem;
        /* Khoảng cách giữa các phần tử */
        padding: 0;
        /* Loại bỏ khoảng cách thừa */
    }

    .checkout .col-lg-8,
    .checkout .col-lg-4 {
        width: 100%;
        /* Đảm bảo cột chiếm hết chiều ngang */

    }

    .checkout .col-lg-8.col-md-6 {
        width: 100%;
        /* Chiếm toàn bộ chiều ngang */
        max-width: 500px;
        /* Giới hạn chiều rộng */
        background-color: #fff;
        /* Màu nền trắng */
        border-radius: 8px;
        /* Bo góc nhẹ */
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
        /* Bóng nhẹ */
        box-sizing: border-box;
        /* Tính toán không bị tràn */
    }

    .checkout .checkout__form {
        padding: 1rem;
        /* Khoảng cách bên trong */
        border-radius: 8px;
        /* Bo góc form */
        background-color: #fff;
        /* Màu nền */
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
        /* Bóng nổi bật */
    }


    .checkout__order {
        padding: 1rem;
        /* Khoảng cách bên trong */
        background-color: var(--bs-light);
        border-radius: 6px;
        /* Bo góc nhẹ */
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        /* Tạo bóng nhẹ */
        box-sizing: border-box;
        /* Tính toán đúng kích thước */
    }

    /* Các thành phần bên trong .checkout__order */
    .checkout__order h4 {
        text-align: center;
        /* Canh giữa tiêu đề */
        margin-bottom: 1rem;
        /* Tạo khoảng cách bên dưới */
    }

    .checkout__order ul {
        padding-left: 2px;
        margin: 0;
        list-style: none;
        /* Loại bỏ gạch đầu dòng */
    }

    .checkout__order li {
        font-size: 0.9rem;
        /* Giảm kích thước chữ */
        padding: 0.5rem 0;
        /* Tạo khoảng cách giữa các dòng */
        border-bottom: 1px solid #eee;
        /* Đường kẻ ngăn cách */
        padding-left: 10px;
    }

    .checkout__order__subtotal,
    .checkout__order__total {
        font-size: 1rem;
        /* Điều chỉnh kích thước chữ */
        margin-top: 1rem;
        /* Khoảng cách phía trên */
        display: flex;
        justify-content: space-between;
        /* Canh đều hai đầu */
    }

    /* Nút bấm đặt hàng */
    .checkout__order .site-btn {
        width: 75%;
        /* Đảm bảo nút bấm chiếm hết chiều ngang */
        padding: 0.75rem;
        /* Khoảng cách bên trong nút */
        text-align: center;
        background-color: var(--colorPrimary) !important;
        color: #fff;
        border: none;
        border-radius: 6px;
        /* Bo góc nút */
        cursor: pointer;
    }

    .checkout__order .site-btn:disabled {
        background-color: #ccc !important;
        /* Màu xám khi bị disabled */
        cursor: not-allowed;
        /* Trạng thái không thể nhấn */
    }

    .checkout__order {
        background-color: var(--bs-light);
        /* Màu nền gốc */
        border-radius: 6px;
        /* Bo góc cho vùng */
        padding: 1.5rem;
        /* Khoảng cách bên trong */
        box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
        /* Bóng mờ mặc định */
        transition: all 0.3s ease;
        /* Hiệu ứng chuyển đổi mượt */
        transform: translateY(0);
        /* Vị trí ban đầu */
    }

    .checkout__order:hover {
        box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
        /* Bóng đậm hơn khi hover */
        transform: translateY(-5px) !important;
        /* Đẩy vùng nhô lên nhẹ */
        background-color: var(--bs-light) !important;
        /* Thay đổi màu nền khi hover */
        border: 1px solid var(--colorPrimary) !important;
        /* Thêm viền nổi bật */
    }

    .checkout__order h4:hover {
        color: var(--colorPrimary) !important;
        /* Thay đổi màu tiêu đề khi hover */
        text-decoration: underline;
        /* Gạch chân tiêu đề */
    }











    .checkout__order {
        padding: 15px;
        background-color: var(--bs-light);
        transition: all 0.3s ease;
        /* Hiệu ứng chuyển đổi mượt */
        transform: translateY(0);
        /* Vị trí ban đầu */
    }

    .checkout__order:hover {
        box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
        /* Bóng đậm hơn khi hover */
        transform: translateY(-5px);
        /* Đẩy vùng nhô lên nhẹ */
        background-color: var(--bs-light);
        /* Thay đổi màu nền khi hover */
        border: 1px solid var(--colorPrimary);
        /* Thêm viền nổi bật */
    }

    .checkout__order h4:hover {
        color: var(--colorPrimary);
        /* Thay đổi màu tiêu đề khi hover */
        text-decoration: underline;
        /* Gạch chân tiêu đề */
    }

    .mobile-layout {
        margin-left: 2px !important;
    }

    a .btn-book {
        width: 23%;
        height: 3rem;
        font-size: 15px;
        padding: 5px 15px;
    }

    .cart {
        width: 50px;
        margin: 0 !important;
    }

    .brand img {
        margin: 0px !important;
    }

    .about {
        text-align: center;
    }

    .help {
        padding: 50px
    }



    .profile-img {
        margin-left: 40%;
    }




    .right-section {
        display: none !important;
    }

    .carousel-inner {
        padding: 0 !important;
    }


    .card-footer .btn {
        font-size: 12px;
    }

    .card-footer-custom .btn {
        width: 95% !important;
        margin-bottom: 10px;
    }

    .product-info {
        margin-top: 20px;
    }

    .btn-buy {
        float: none !important;
    }

    .tinh-height90 {
        padding: 0 !important;
    }

    #decrement,
    #increment {
        width: 25px !important;
        height: 25px !important;
        border-radius: 50%;
        padding: 0;
    }

    .text-login {
        display: none;
    }

    .head-login {
        font-size: 20px !important;
    }


    .ps28277 .container span {
        font-size: 10px;
    }



}

