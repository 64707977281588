.form-booktable {
    background-color: var(--body-color);
    padding: 20px;
    border-radius: 10px;
}

.form-booktable h3 {
    font-weight: bold;
    color: var(--colorPrimary);
}

.form-booktable input::placeholder {
    color: var(--text-color); /* Màu đỏ */
    opacity: 1; /* Đảm bảo màu hiển thị đầy đủ */
}
.form-booktable textarea::placeholder {
    color: var(--text-color); /* Màu đỏ */
    opacity: 1; /* Đảm bảo màu hiển thị đầy đủ */
}



.form-booktable input:focus {
    color: var(--text-color); /* Màu đỏ */
    background-color: transparent;
}
.form-booktable textarea:focus {
    color: var(--text-color); /* Màu đỏ */
    background-color: transparent;
}

.right-section {
    padding-left: 0;
}
.form-control {
    color: var(--text-color);
    /* background-color: var(--colorPrimary); */
    border: none;
    border-bottom: 1px solid white;
    padding: 8px 0;
    font-size: 16px;
    border-radius: 0;
}

.form-control:focus {
    box-shadow: none;
    /* border-bottom: 1px solid #ffb347; */
}

.form-label {
    font-size: 14px;
    color: #999;
    margin-bottom: 5px;
}

.right-section .form-check-input {
    background-color: var(--colorPrimary);
    border: 1px solid var(--colorPrimary);
}

.btn-reservation {
    color: var(--colorPrimary);
    border: 2px solid var(--colorPrimary);
    padding: 8px 15px;
    border-radius: 20px;
    font-weight: bold;
    background: none;
}

.btn-reservation:hover {
    background-color: var(--colorPrimary);
    color: white;
}