  /*=============== GOOGLE FONTS ===============*/
  @import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:opsz,wght@6..12,200..1000&display=swap");

  /*=============== VARIABLES CSS ===============*/
  :root {
    --header-height: 3.5rem;

    /*========== Colors ==========*/
    /*Color mode HSL(hue, saturation, lightness)*/
 

    /*========== Font and typography ==========*/
    /*.5rem = 8px | 1rem = 16px ...*/
    --body-font: "Nunito Sans", system-ui;
    --normal-font-size: .938rem;
    --smaller-font-size: .75rem;
    --tiny-font-size: .75rem;

    /*========== Font weight ==========*/
    --font-regular: 400;
    --font-semi-bold: 600;

    /*========== z index ==========*/
    --z-tooltip: 10;
    --z-fixed: 100;
  }

  /*========== Responsive typography ==========*/
  @media screen and (min-width: 1150px) {
    :root {
      --normal-font-size: 1rem;
      --smaller-font-size: .813rem;
    }
  }

  /*=============== BASE ===============*/
  * {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
  }

  body {
    font-family: var(--body-font);
    font-size: var(--normal-font-size);
    background-color: white;
    color: var(--text-color);
    transition: background-color .4s;
  }

  a {
    text-decoration: none;
  }

  img {
    display: block;
    max-width: 100%;
    height: auto;
  }

  button {
    all: unset;
  }

  .sidebar__img .logo {
    width: 120px;
    height: 80px;
    background-image: var(--logo); /* Đường dẫn đến hình ảnh logo */
    background-size: contain; /* Điều chỉnh kích thước hình ảnh */
    background-repeat: no-repeat; /* Không lặp lại hình ảnh */
    background-position: center; /* Căn giữa hình ảnh */
    /* Tùy chọn thêm: */
    display: block; /* Đảm bảo div có kích thước */
  }

  /*=============== VARIABLES DARK THEME ===============*/


  /*========== 
    Color changes in some parts of 
    the website, in dark theme
  ==========*/
  .dark-theme .sidebar__content::-webkit-scrollbar {
    background-color: hsl(228, 16%, 30%);
  }

  .dark-theme .sidebar__content::-webkit-scrollbar-thumb {
    background-color: hsl(228, 16%, 40%);
  }

  /*=============== REUSABLE CSS CLASSES ===============*/
  .container_staff {
    margin-inline: 1.5rem;
  }

  .main {
    padding-top: 5rem;
  }

  /*=============== HEADER ===============*/
  .header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: var(--z-fixed);
    margin: .75rem;
    width: auto;
  }


  .header__container {
    width: 100%;
    height: var(--header-height);
    background-color: var(--body-color);
    box-shadow: 0 2px 24px var(--shadow-color);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-inline: 1.5rem;
    border-radius: 1rem;
    transition: background-color .4s;
  }

  .footer {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: var(--z-fixed);
    margin: .75rem;
    padding-left: 340px;
  }

  .footer__container {
    width: 100%;
    height: var(--header-height);
    height: calc(var(--header-height) + 2rem);
    background-color: var(--body-color);
    box-shadow: 0 2px 24px var(--shadow-color);
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-inline: 1.5rem;
    border-radius: 1rem;
    transition: background-color .4s;
  }

  .header__logo {
    display: inline-flex;
    align-items: center;
    column-gap: .25rem;
  }

  .header__logo i {
    font-size: 1.5rem;
    color: var(--first-color);
  }

  .header__logo span {
    color: var(--title-color);
    font-weight: var(--font-semi-bold);
  }

  .header__toggle {
    font-size: 1.5rem;
    color: var(--title-color);
    cursor: pointer;
  }

  /*=============== SIDEBAR ===============*/
  .sidebar {
    position: fixed;
    left: -120%;
    top: 0;
    bottom: 0;
    z-index: var(--z-fixed);
    width: 288px;
    background-color: var(--body-color);
    box-shadow: 2px 0 24px var(--shadow-color);
    padding-block: 1.5rem;
    margin: .75rem;
    border-radius: 1rem;
    transition: left .4s, background-color .4s, width .4s;
  }

  .sidebar__container,
  .sidebar__content {
    display: flex;
    flex-direction: column;
    row-gap: 3rem;
  }

  .sidebar__container {
    height: 100%;
    overflow: hidden;
  }

  .sidebar__user {
    display: grid;
    grid-template-columns: repeat(2, max-content);
    align-items: center;
    column-gap: 1rem;
    padding-left: 2rem;
  }

  .sidebar__img {
    position: relative;
    width: 120px;
    height: 100px;
    overflow: hidden;
    display: grid;
    justify-items: center;
  }

  .sidebar__img img {
    position: absolute;
    width: 120px;
    bottom: 1px;
    top: 50%;
    transform: translateY(-50%);
  }

  .sidebar__info h3 {
    font-size: var(--normal-font-size);
    color: var(--title-color);
    transition: color .4s;
  }

  .sidebar__info span {
    font-size: var(--smaller-font-size);
  }

  .sidebar__content {
    overflow: hidden auto;
  }

  .sidebar__content::-webkit-scrollbar {
    width: .4rem;
    background-color: hsl(228, 8%, 85%);
  }

  .sidebar__content::-webkit-scrollbar-thumb {
    background-color: hsl(228, 8%, 75%);
  }

  .sidebar__title {
    color: var(--text-color);
    width: max-content;
    font-size: var(--tiny-font-size);
    font-weight: var(--font-semi-bold);
    padding-left: 2rem;
    margin-bottom: 1.5rem;
  }

  .sidebar__list,
  .sidebar__actions {
    display: grid;
    row-gap: 1rem;
  }

  .sidebar__link {
    cursor: pointer;
    position: relative;
    display: grid;
    grid-template-columns: repeat(2, max-content);
    align-items: center;
    column-gap: 1rem;
    color: var(--text-color);
    padding-left: 2rem;
    transition: color .4s, opacity .4s;
  }

  .sidebar__link i {
    font-size: 1.25rem;
  }

  .sidebar__link span {
    font-weight: var(--font-semi-bold);
  }

  .sidebar__link:hover {
    color: var(--first-color);
  }

  .sidebar__actions {
    margin-top: auto;
  }

  .sidebar__actions button {
    cursor: pointer;
  }

  .sidebar__theme {
    width: 100%;
    font-size: 1.25rem;
  }

  .sidebar__theme span {
    font-size: var(--normal-font-size);
    font-family: var(--body-font);
  }

  /* Show sidebar */
  .show-sidebar {
    left: 0;
  }

  /* Active link */
  .active-link {
    color: var(--first-color);
  }

  .active-link::after {
    content: "";
    position: absolute;
    left: 0;
    width: 3px;
    height: 20px;
    background-color: var(--first-color);
  }

  .table-card {
    position: relative;
    margin-bottom: 15px;
    height: 178px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    cursor: pointer;
    background-color: var(--body-color-table);
  }

  .table-card-active {
    background-color: var(--body-color-table-active);
  }

  .table-card h5 {
    color: var(--title-color);
  }

  .dropdown-menu {
    min-width: 120px;
  }

  .status-circle {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: green;
  }

  .table-status {
    font-size: 0.85em;
    color: gray;
  }

  .total-amount {
    font-weight: bold;
    color: var(--first-color);
  }

  #decrement {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    padding: 0;
    background-color: var(--first-color) ;
    border: none;
  }

  #increment {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    padding: 0;
    background-color: var(--first-color) ;
    border: none;
  }

  #increment-again {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    padding: 0;
    background-color: var(--first-color) ;
    border: none;
  }

  #quantity-controls {
    display: flex;
    align-items: center;
  }

  #quantity {
    font-size: 1rem;
  }

  .card-custom input[type="number"] {
    -moz-appearance: textfield;
    text-align: center;
    font-size: 16px;
    border: none;
    color: #202030;
    width: 35px;
  }

  .card-custom {
    background-color: var(--body-color-table);
    color: var(--title-color);
  }

  .quantity-custom {
    color: var(--text-color) !important;
  }

  .card-custom button {
    color: #fff;
    background-color: #a22121;
    border: 1px solid;
    font-size: 1rem;
    cursor: pointer;
  }

  /*=============== BREAKPOINTS ===============*/
  /* For small devices */
  @media screen and (max-width: 360px) {
    .header__container {
      padding-inline: 1rem;
    }

    .sidebar {
      width: max-content;
    }

    .sidebar__info,
    .sidebar__link span {
      display: none;
    }

    .sidebar__user,
    .sidebar__list,
    .sidebar__actions {
      justify-content: center;
    }

    .sidebar__user,
    .sidebar__link {
      grid-template-columns: max-content;
    }

    .sidebar__user {
      padding: 0;
    }

    .sidebar__link {
      padding-inline: 2rem;
    }

    .sidebar__title {
      padding-inline: .5rem;
      margin-inline: auto;
    }
  }

  /* For large devices */
  @media screen and (min-width: 1150px) {
    .header {
      margin: 1rem;
      padding-left: 340px;
      transition: padding .4s;
    }

    .header__container {
      height: calc(var(--header-height) + 2rem);
      padding-inline: 2rem;
    }

    .header__logo {
      order: 1;
    }

    .sidebar {
      left: 0;
      width: 316px;
      margin: 1rem;
    }

    .sidebar__info,
    .sidebar__link span {
      transition: opacity .4s;
    }

    .sidebar__user,
    .sidebar__title {
      transition: padding .4s;
    }

    /* Reduce sidebar */
    .show-sidebar {
      width: 90px;
    }

    .show-sidebar .sidebar__user {
      padding-left: 0;
    }

    .show-sidebar .sidebar__user .sidebar__img {
      width: 90px;
    }

    .show-sidebar .sidebar__title {
      padding-left: 0;
      margin-inline: auto;
    }

    .show-sidebar .sidebar__info,
    .show-sidebar .sidebar__link span {
      opacity: 0;
    }

    .main {
      padding-left: 340px;
      padding-top: 8rem;
      transition: padding .4s;
    }

    /* Add padding left */
    .left-pd {
      padding-left: 114px;
    }

  }


  .offcanvas,
  .offcanvas-lg,
  .offcanvas-md,
  .offcanvas-sm,
  .offcanvas-xl,
  .offcanvas-xxl {
    width: 505px !important;
  }


  .ps36231 .modal-content {
    background-color: #f8f9fa;
    /* Màu nền của modal */
    border-radius: 10px;
    /* Bo góc modal */
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    /* Đổ bóng */
  }

  .ps36231 h3 {
    font-size: 1.5rem;
    font-weight: bold;
    color: #333;
  }

  .ps36231 p {
    color: #666;
    font-size: 1rem;
  }

  .ps36231 .btn-primary {
    background-color: var(--first-color);
    border: none;
    padding: 0.5rem 1.5rem;
    border-radius: 5px;
  }

  .ps36231 .btn-primary:hover {
    background-color: #973737;
  }

  :where(.container_staff, form, .input-field, header) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .container_staff {
    background: #fff;
    padding: 30px 35px;
    border-radius: 12px;
    row-gap: 20px;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  }

  .container_staff header {
    height: 65px;
    width: 65px;
    background: var(--first-color);
    color: #fff;
    font-size: 2.5rem;
    border-radius: 50%;
  }

  .container_staff h4 {
    font-size: 1.25rem;
    color: #333;
    font-weight: 500;
  }

  form .input-field {
    flex-direction: row;
    column-gap: 10px;
  }

  .input-field input {
    height: 40px;
    width: 100%;
    border-radius: 6px;
    outline: none;
    font-size: 1.125rem;
    text-align: center;
    border: 1px solid #ddd;
  }

  .input-field input:focus {
    box-shadow: 0 1px 0 rgba(0, 0, 0, 0.1);
  }

  .input-field input::-webkit-inner-spin-button,
  .input-field input::-webkit-outer-spin-button {
    display: none;
  }

  form .btn-link {
    margin-top: 25px;
    width: 100%;
    color: #fff;
    font-size: 1rem;
    border: none;
    padding: 9px 0;
    cursor: pointer;
    border-radius: 6px;
    background: var(--first-color);
    transition: all 0.2s ease;
    text-decoration: none;
  }

  .btn-link:hover {
    color: white;
  }

  .input-field {
    display: flex;
    gap: 10px;
  }

  .otp-input {
    width: 100%;
    text-align: center;
    font-size: 1.5rem;
  }

  .btn-link:not(.disabled-link) {
    cursor: pointer;
  }

  .btn-link.disabled-link {
    background-color: #ccc;
    /* Màu xám khi chưa đủ input */
  }

  .btn-link:not(.disabled-link):hover {
    background-color: #973737;
  }

  .btn-danger-active {
    background-color: var(--first-color);
    color: white;
  }
