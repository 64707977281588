.ps36231-reset-password-otp{
    display: flex;
    margin: auto auto;
    min-height: 600px;
    justify-content: center;
    align-items: center;
}

.ps36231-reset-password-otp > form > .box-reset-password-otp{
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0 3px 10px 0 rgba(0, 0, 0, .14);
    width: 500px;
    min-height: 275px;
    padding: 25px;
}

.ps36231-reset-password-otp .box-reset-password-otp .back-to-enter-otp{
    margin-right: 40px;
}

.ps36231-reset-password-otp .box-reset-password-otp .back-to-enter-otp > i{
    color: #bd4242;
    font-size: 30px;
}

.ps36231-reset-password-otp .title > div{
    font-size: 1.25rem;
}

.ps36231-reset-password-otp .input-password{
    padding: 30px 50px 0 50px;
}

.ps36231-reset-password-otp .input-password > div.d-flex{
    width: 100%;
    padding: 8px;
    outline: none;
    border: 1px solid rgba(0, 0, 0, .14);
    border-radius: 2px;
    box-shadow: inset 0 2px 0 rgba(0, 0, 0, .02);
    margin-top: 10px;
}

.ps36231-reset-password-otp .input-password > div > input{
    border: unset;
    outline: none;
    width: 90%;
    font-size: 14px;
}

.ps36231-reset-password-otp .input-password > div > button{
    width: 10%;
    background-color: transparent;
    outline: none;
    border: unset;
}

.ps36231-reset-password-otp .input-password > .next-to-reset-password{
    margin-top: 20px;
    text-decoration: none;
    background-color: #bd4242;
    font-size: 15px;
    padding: 8px 0;
    color: white;
    text-align: center;
    text-transform: uppercase;
    font-weight: 500;
}

.ps36231-reset-password-otp .input-password .align-number{
    border-top: 1px solid rgba(0, 0, 0, .26);
    width: 35px;
    margin-right: 20px;
}

.ps36231-reset-password-otp .input-password .note-password{
    text-align: center;
}

.ps36231-reset-password-otp .input-password .note-password > p{
    margin-bottom: 8px;
}

.ps36231-reset-password-otp .input-password .note-password > span{
    font-size: larger;
}

.ps36231-reset-password-otp .input-password > span{
    margin-top: 5px;
    color: gray;
    font-size: 13px;
}

@media screen and (max-width: 991px) {
    .ps36231-reset-password-otp{
        width: 991px;
        height: 800px;
    }

    .ps36231-reset-password-otp > form > .box-reset-password-otp{
        width: 700px;
        padding: 35px;
    }

    .ps36231-reset-password-otp .box-reset-password-otp .back-to-enter-phone{
        margin-right: 60px;
    }

    .ps36231-reset-password-otp .box-reset-password-otp .back-to-enter-otp > i{
        font-size: 50px;
    }

    .ps36231-reset-password-otp .title > div{
        font-size: 40px;
        margin: 20px 0;
        height: 76px;
    }

    .ps36231-reset-password-otp .input-password{
        padding-top: 0;
    }

    .ps36231-reset-password-otp .input-password > div > input{
        border: unset;
        outline: none;
        width: 80%;
        font-size: 30px;
        padding-left: 20px;
    }

    .ps36231-reset-password-otp .input-password .note-password > p{
        font-size: 30px;
    }
    
    .ps36231-reset-password-otp .input-password .note-password > span{
        font-size: 35px;
    }

    .ps36231-reset-password-otp .input-password > span{
        margin-top: 10px;
        font-size: 25px;
    }
    
    .ps36231-reset-password-otp .input-password > div > button{
        width: 20%;
        background-color: transparent;
        outline: none;
        border: unset;
        font-size: 25px;
    }

    .ps36231-reset-password-otp .input-password > .next-to-reset-password{
        font-size: 25px;
        padding: 10px 0;
    }
}    
