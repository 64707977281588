.ps36231 .modal-content {
    border-radius: 16px;
}

.ps36231 .control-img {
    position: sticky;
    overflow: hidden;
    border-radius: 12px;
}

.ps36231 .control-img>img {
    width: 100%;
    height: 250px;
    object-fit: cover;
}

.ps36231 .container-modal {
    padding: 16px;
}

.ps36231 .modal-content .container-button {
    position: absolute;
    top: 16px;
    right: 16px;
    box-shadow: none !important;
    background-color: white;
    width: 32px;
    height: 32px;
    align-items: center;
    justify-content: center;
    border-radius: 100px;
}

.ps36231 .container-modal-footer {
    margin-top: 16px;
}

.ps36231 .container-modal-footer .name-item {
    text-transform: capitalize;
    color: #000;
    font-size: 20px;
    font-weight: bold;
    line-height: 28px;
}

.ps36231 .container-modal-footer .capacity-item {
    color: #248FED;
}

.ps36231 .container-modal-footer .capacity-item-details {
    margin-top: 16px;
}

.ps36231 .container-modal-footer .capacity-item-details .capacity-item-name {
    color: #000;
    font-size: 16px;
    margin-top: 16px;
    font-weight: bold;
}

.ps36231 .container-modal-footer .capacity-item-details .capacity-item-milli {
    font-size: 14px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 4;
}

.ps36231 .container-modal-footer .capacity-item-details .see-more {
    font-size: 14px;
    color: var(--first-color);
}

.ps36231 .container-modal-footer .container-price-quantity {
    display: grid;
    gap: 12px;
    margin-top: 16px;
    grid-template-columns: 1fr 1fr
}

.ps36231 .container-modal-footer .container-price-quantity .price-quantity>.price {
    font-size: 20px;
    color: var(--first-color);
    font-weight: bold;
    line-height: 1.5;
}

.ps36231 .container-modal-footer .container-price-quantity .price-quantity>.quantity-control {
    display: flex;
}

.ps36231 .price-quantity>.quantity-control>.plus,
.ps36231 .price-quantity>.quantity-control>.minus {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    border: 1px solid #D8D8D8;
    height: 32px;
    border-radius: 100px;
    cursor: pointer;
}

.ps36231 .price-quantity>.quantity-control>.plus>span,
.ps36231 .price-quantity>.quantity-control>.minus>span {
    font-size: 20px;
    color: var(--first-color);
}

.ps36231 .price-quantity>.quantity-control>.quantity {
    margin: 0 16px;
    color: black;
    font-size: 20px;
    font-weight: bold;
}

.ps36231 .container-modal-footer .container-price-quantity .control-btn-add-to-cart {
    display: flex;
}

.ps36231 .container-modal-footer .container-price-quantity .control-btn-add-to-cart>button {
    outline: none;
    color: white;
    background-color: var(--first-color);
    font-size: 16px;
    font-weight: bold;
    display: flex;
    flex: 1;
    padding: 10px 12px;
    border-radius: 8px;
    justify-content: center;
    align-items: center;
    border: 2px solid transparent;
    transition: 0.2s ease-in;
}

.ps36231 .container-modal-footer .container-price-quantity .control-btn-add-to-cart>button:hover {
    color: var(--first-color);
    background-color: transparent;
    border: 2px solid var(--first-color);
}

.modal-content {
    z-index: 1050;
    /* Đảm bảo modal hiển thị ở trên */
}

.modal-backdrop {
    z-index: 1040;
    /* Nền sẽ nằm dưới modal */
}