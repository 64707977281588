.ps36231-promotion-detail .inner-container{
   margin-top: 10%;
}

.ps36231-promotion-detail .control-img .container-img{
    width: 100%;
    overflow: hidden;
}

.ps36231-promotion-detail .container-img > img{
    width: 100%;
    height: auto;
    object-fit: cover;
    border-radius: 10px;
}

.ps36231-promotion-detail .all-infor-time > h1{
    font-size: 1.5rem;
    padding-bottom: 0.3rem;
}

.ps36231-promotion-detail .all-infor-time > table{
    margin-top: 1rem;
    margin-bottom: 1.25rem;
}

.ps36231-promotion-detail .all-infor-time > table .first-tr-in-table{
    border-bottom: 0.5px solid rgb(136, 136, 136); 
    border-top: 0.5px solid rgb(136, 136, 136);
}

.ps36231-promotion-detail .all-infor-time > table td{
    text-align: right;
}

.ps36231-promotion-detail .all-infor-time > table > tbody > tr >*{
    padding-top: .25rem;
    padding-bottom: .25rem;
    font-weight: 400;
}

.ps36231-promotion-detail .all-infor-time .btn-get-promotion > button{
    width: 100%;
    height: 44px;
    border-radius: .25rem;
    background-color: #F99F36;
    padding: .5rem;
    font-weight: 600;
    color: white;
    border: unset;
    outline: none;
}

.ps36231-promotion-detail .all-infor-time .btn-get-promotion > button:disabled{
    background-color: #f4b873;
}

.ps36231-promotion-detail .content h2{
    font-size: inherit;
    font-weight: 600;
}

.ps36231-promotion-detail .content div p{
    margin: 0;
}


@media screen and (max-width: 991px) {
    .ps36231-promotion-detail {
        padding-left: 20px !important;
        padding-right: 20px !important;
    }

    .ps36231-promotion-detail .container-img > img{
        border-radius: unset;
    }

    .ps36231-promotion-detail .all-infor-time > h1{
        margin-top: 10px;
    }

    .ps36231-promotion-detail .all-infor-time .btn-get-promotion > button{
        margin-bottom: 20px;
    }
}