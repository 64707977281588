.ps36231-checkout-staff-1 .call-staff {
    display: flex;
    justify-content: center;
}

.ps36231-checkout-staff-1 .call-staff>div {
    margin-top: 20px;
    width: 700px;
}

.ps36231-checkout-staff-1 .call-staff>div>.turn-back>a,
.ps36231-checkout-staff-1 .call-staff>div>.turn-dashboard>a {
    font-size: 16px;
    text-decoration: none;
    color: black;
}

.ps36231-checkout-staff-1 .call-staff-inner {
    background-color: var(--first-color);
    color: white;
    display: flex;
    justify-content: space-between;
    padding: 8px 20px;
    border-radius: 20px;
    font-size: 15px;
    width: 170px;
}

.ps36231-checkout-staff-1 .title-table {
    text-align: center;
    font-size: 25px;
    margin-top: 10px;
    padding-bottom: 20px;
}

.ps36231-checkout-staff-1 .title-red {
    color: rgb(206, 0, 0);
}

.ps36231-checkout-staff-1 .container-table {
    display: flex;
    justify-content: center;
}

.ps36231-checkout-staff-1 .container-table>div {
    display: flex;
    width: 700px;
    max-height: 200px;
    overflow-y: auto;
    scrollbar-width: none;
    justify-content: center;
}

.ps36231-checkout-staff-1 .container-table>div::-webkit-scrollbar {
    display: none;
}

.ps36231-checkout-staff-1 .container-table .all-sp {
    width: 100%;
    font-size: 18px;
}

.ps36231-checkout-staff-1 .container-table .all-sp thead th {
    padding: 10px 0;
    position: sticky;
    top: 0;
    z-index: 1;
    background-color: white;
}

.ps36231-checkout-staff-1 .container-table .all-sp thead th:nth-child(2) {
    text-align: right;
}

.ps36231-checkout-staff-1 .container-table .all-sp tr td:nth-child(2) {
    text-align: right;
}

.ps36231-checkout-staff-1 .container-button {
    display: flex;
    justify-content: center;
    padding-top: 30px;
    padding-bottom: 40px;
}

.ps36231-checkout-staff-1 .container-button>a {
    padding: 10px;
    background-color: var(--first-color);
    width: 200px;
    text-align: center;
    text-decoration: none;
    color: white;
    font-size: 20px;
    border-radius: 3px;
}

.ps36231-checkout-staff-1 .container-button>button {
    padding: 10px;
    background-color: var(--first-color);
    width: 200px;
    text-align: center;
    text-decoration: none;
    color: white;
    font-size: 20px;
    border-radius: 3px;
}

.ps36231-checkout-staff-1 .step-checkout {
    background-color: rgb(180, 180, 180);
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: center;
}

.ps36231-checkout-staff-1 .step-checkout div {
    display: flex;
    justify-content: space-between;
    flex-flow: row;
    width: 200px;
    position: absolute;
    top: -20px;
}

.ps36231-checkout-staff-1 .step-checkout div button {
    width: 40px;
    height: 40px;
    background-color: #8b8989;
    border: 3px solid white;
    display: grid;
    place-items: center;
    color: white;
    text-decoration: none;
    border-radius: 50%;
}

.ps36231-checkout-staff-1 .container-table input {
    margin-top: 80px;
    width: 500px;
    padding: 10px 20px;
    font-size: 20px;
    border-left: none;
    border-right: none;
    border-top: none;
    outline: none;
}

.ps36231-checkout-staff-1 .container-price-all-sp {
    display: flex;
    justify-content: center;
}

.ps36231-checkout-staff-1 .container-price-all-sp div {
    width: 700px;
    margin: 20px 0;
}

.ps36231-checkout-staff-1 .container-price-all-sp div table {
    width: 100%;
    font-size: 20px;
}

.ps36231-checkout-staff-1 .container-price-all-sp div table thead th:nth-child(2) {
    text-align: right;
    color: red;
}

.ps36231-checkout-staff-1 .container-method-checkout {
    display: flex;
    justify-content: center;
    height: 150px;
}

.ps36231-checkout-staff-1 .container-method-checkout>div {
    width: 700px;
    border-top: 1px solid gray;
}

.ps36231-checkout-staff-1 .container-method-checkout .title-all-method-payment {
    display: flex;
    align-items: center;
    margin-top: 15px;
}

.ps36231-checkout-staff-1 .container-method-checkout .title-all-method-payment>div {
    width: 35px;
    height: 35px;
    background-color: var(--first-color);
    border-radius: 50%;
    display: grid;
    place-items: center;
    margin-right: 10px;
}

.ps36231-checkout-staff-1 .container-method-checkout .title-all-method-payment>h3 {
    font-size: 20px;
    font-weight: bold;
}

.ps36231-checkout-staff-1 .container-method-checkout .all-div-method-payment {
    display: flex;
    padding-top: 20px;
}

.ps36231-checkout-staff-1 .container-method-checkout .all-div-method-payment>div {
    width: 60px;
    background-color: white;
    margin-right: 20px;
    overflow: hidden;
    border-radius: 10px;
    border: 2px solid grey;
    cursor: pointer;
}

.ps36231-checkout-staff-1 .container-method-checkout .all-div-method-payment>.selected {
    border: 2px solid var(--first-color);
}

.ps36231-checkout-staff-1 .container-method-checkout .all-div-method-payment>div>.control-img {
    width: 100%;
}

.ps36231-checkout-staff-1 .container-method-checkout .all-div-method-payment>div>.control-img>img {
    width: 100%;
    height: auto;
    object-fit: cover;
}

.ps36231-checkout-staff-1 .modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.ps36231-checkout-staff-1 .modal-content {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    text-align: center;
}

.ps36231-checkout-staff-1 h5:hover {
    color: var(--first-color) !important;
}


.congratulation-wrapper {
    margin-top: 150px;
    max-width: 550px;
    margin-inline: auto;
    padding: 30px 20px;
    border-radius: 10px;
}

.congratulation-contents.center-text .congratulation-contents-icon {
    margin-inline: auto;
}

.congratulation-contents-icon {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    height: 100px;
    width: 100px;
    background-color: #65c18c;
    color: #fff;
    font-size: 50px;
    border-radius: 50%;
    margin-bottom: 30px;
}

.congratulation-contents-title {
    font-size: 32px;
    line-height: 36px;
    margin: -6px 0 0;
}

.btn-wrapper {
    display: flex;
    justify-content: center;
}

.congratulation-contents-para {
    font-size: 16px;
    line-height: 24px;
    margin-top: 15px;
}

.cmn-btn.btn-bg-1 {
    background: var(--first-color);
    color: #fff;
    border: 2px solid transparent;
    border-radius: 3px;
    text-decoration: none;
    padding: 5px;
    width: 200px;
    cursor: pointer;
}

@media (max-width: 768px) {
    .ps36231-checkout-staff-1 .container-table>div {
        padding: 0 20px;
    }

    .ps36231-checkout-staff-1 .call-staff>div {
        padding: 0 20px;
    }

    .ps36231-checkout-staff-1 .container-button>button {
        width: 150px;
        padding: 5px;
    }

    .ps36231-checkout-staff-1 .container-table input {
        width: auto;
        padding: 5px 15px;
    }

    .ps36231-checkout-staff-1 .title-table {
        padding: 10px 20px 20px 20px;
    }
    .container-price-all-sp {
        padding: 0 20px;
    }

    .container-method-checkout {
        padding: 0 20px;
    }

    .title-all-method-payment h3 {
        font-size: 16px !important;
    }
}