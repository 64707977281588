body{
	display: block;
}
.breadcrumb-section {
	display: flex;
	align-items: center;
	padding: 45px 0 40px;
}

.breadcrumb__text h2 {
	font-size: 46px;
	color: #ffffff;
	font-weight: 700;
}

.qr-code-image{
	height: 10%;
	width: 50%;
}

.qr-code-container{
	display: flex;
	justify-content: center;
}

.breadcrumb__option a {
	display: inline-block;
	font-size: 16px;
	color: #ffffff;
	font-weight: 700;
	margin-right: 20px;
	position: relative;
}

.breadcrumb__option a:after {
	position: absolute;
	right: -12px;
	top: 13px;
	height: 1px;
	width: 10px;
	background: #ffffff;
	content: "";
}

.breadcrumb__option span {
	display: inline-block;
	font-size: 16px;
	color: #ffffff;
}

.shoping__checkout {
	background: #f5f5f5;
	padding: 30px;
	padding-top: 20px;
	margin-top: 50px;
}

.shoping__checkout h5 {
	color: #1c1c1c;
	font-weight: 700;
	font-size: 20px;
	margin-bottom: 28px;
}

.shoping__checkout ul {
	margin-bottom: 28px;
}

.shoping__checkout ul li {
	font-size: 16px;
	color: #1c1c1c;
	font-weight: 700;
	list-style: none;
	overflow: hidden;
	border-bottom: 1px solid #ebebeb;
	padding-bottom: 13px;
	margin-bottom: 18px;
}

.shoping__checkout ul li:last-child {
	padding-bottom: 0;
	border-bottom: none;
	margin-bottom: 0;
}

.shoping__checkout ul li span {
	font-size: 18px;
	color: #dd2222;
	float: right;
}

.shoping__checkout .primary-btn {
	display: block;
	text-align: center;
}

/*---------------------
  Checkout
-----------------------*/

.checkout {
	padding-top: 4%;
}

.checkout h6 {
	color: #999999;
	text-align: center;
	background: #f5f5f5;
	border-top: 1px solid var(--colorPrimary);
	padding: 12px 0 12px;
	margin-bottom: 75px;
}

.checkout h6 span {
	font-size: 16px;
	color: #6AB963;
	margin-right: 5px;
}

.checkout h6 a {
	text-decoration: underline;
	color: #999999;
}

.checkout__form h4 {
	color: #1c1c1c;
	font-weight: 700;
	border-bottom: 1px solid #e1e1e1;
	padding-bottom: 20px;
	margin-bottom: 25px;
}

.checkout__form p {
	column-rule: #b2b2b2;
}

.checkout__input {
	margin-bottom: 24px;
}

.checkout__input p {
	color: #1c1c1c;
	margin-bottom: 20px;
}

.checkout__input p span {
	color: #dd2222;
}

.checkout__input input {
	width: 100%;
	height: 46px;
	border: 1px solid #ebebeb;
	padding-left: 10px;
	font-size: 16px;
	color: #b2b2b2;
	border-radius: 4px;
}

.checkout__input textarea {
	width: 100%;
	border: 1px solid #ebebeb;
	padding-left: 10px;
	font-size: 16px;
	color: #b2b2b2;
	border-radius: 4px;
	height: 150px;
	padding-top: 10px;
}


.checkout__input input.checkout__input__add {
	margin-bottom: 20px;
}

.checkout__input input::placeholder {
	color: #b2b2b2;
}

.checkout__input__checkbox {
	margin-bottom: 10px;
}

.checkout__input__checkbox label {
	position: relative;
	font-size: 16px;
	color: #1c1c1c;
	padding-left: 20px;
	cursor: pointer;
	color: #b2b2b2;
}

.checkout__input__checkbox label input {
	position: absolute;
	visibility: hidden;
}

.checkout__input__checkbox label input:checked~.checkmark {
	background: #7fad39;
	border-color: #7fad39;
}

.checkout__input__checkbox label input:checked~.checkmark:after {
	opacity: 1;
}

.checkout__input__checkbox label .checkmark {
	position: absolute;
	left: 0;
	top: 4px;
	height: 16px;
	width: 16px;
	border: 1px solid #a6a6a6;
	content: "";
	border-radius: 16px;
}

.checkout__input__checkbox label .checkmark:after {
	position: absolute;
	left: 2px;
	top: 1px;
	width: 10px;
	height: 8px;
	border: solid white;
	border-width: 3px 3px 0px 0px;
	-webkit-transform: rotate(127deg);
	-ms-transform: rotate(127deg);
	transform: rotate(127deg);
	content: "";
	opacity: 0;
}

.checkout__order {
	background: #f5f5f5;
	border-radius: 10px;
	padding: 10px;
	width: 65vh;
}

.checkout__order h4 {
	color: #1c1c1c;
	font-weight: 700;
	border-bottom: 1px solid #e1e1e1;
	padding-bottom: 20px;
	margin-bottom: 20px;
}

.checkout__order .checkout__order__products {
	font-size: 18px;
	color: #1c1c1c;
	font-weight: 700;
	margin-bottom: 10px;
}

.checkout__order .checkout__order__products span {
	float: right;
}

.checkout__order ul {
	margin-bottom: 12px;
}

.checkout__order ul li {
	font-size: 16px;
	color: #6f6f6f;
	line-height: 40px;
	list-style: none;
}

.checkout__order ul li span {
	font-weight: 700;
	float: right;
}

.checkout__order .checkout__order__subtotal {
	font-size: 18px;
	color: #1c1c1c;
	font-weight: 700;
	border-bottom: 1px solid #e1e1e1;
	border-top: 1px solid #e1e1e1;
	padding-bottom: 15px;
	margin-bottom: 15px;
	padding-top: 15px;
}

.checkout__order .checkout__order__coupon {
	font-size: 18px;
	color: #1c1c1c;
	font-weight: 700;
	border-top: 1px solid #e1e1e1;
	padding-bottom: 15px;
	padding-top: 15px;
}

.checkout__order .checkout__order__subtotal span {
	float: right;
}

.checkout__order .checkout__input__checkbox label {
	padding-left: 20px;
}

.checkout__order .checkout__order__total {
	font-size: 18px;
	color: #1c1c1c;
	font-weight: 700;
	border-bottom: 1px solid #e1e1e1;
	padding-bottom: 15px;
	margin-bottom: 25px;
}

.checkout__order .checkout__order__total span {
	float: right;
	color: #dd2222;
}

.checkoyt_submit{
	display: flex;
	justify-content: center;
}
.checkout__order button:hover {
	background-color: var(--colorPrimary);
	color: white;
}

.modal-overlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.5);
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 1000;
}

.modal-content {
	background: white;
	padding: 20px;
	border-radius: 8px;
	max-width: 500px;
	width: 90%;
	box-shadow: 0 5px 15px rgba(0,0,0,.5);
}

.modal-content h5 {
	margin-bottom: 15px;
}

.modal-content p {
	margin-bottom: 20px;
}

.btn {
	padding: 10px 20px;
	border: none;
	border-radius: 4px;
	cursor: pointer;
}

.btn-secondary {
	background-color: #6c757d;
	color: white;
}

.btn-secondary:hover {
	background-color: #5a6268;
}